import dynamic from 'next/dynamic'

export const TEMPLATES = {
  "fni-plan": {
    editable: [ 
      { field: 'sale_price', label: 'Sale or Discounted Price', original: true },
      { field: 'comment', label: 'Comments' },
      { field: 'addition_link', label: 'Link to more info' },
    ],
  },
}

export const importView = (template, name) => {
  const path = '@/sites/' + template + '/' + name
  const DynamicComponent = dynamic(() =>
    import('./' + template).catch(() => 
      console.log("ERROR: Template not found: " + template)
    )
  )
  return DynamicComponent
}

export function loadTemplate(template, props) {
  try {
    const Template = importView(template, name)
    return <Template {...props} key={template}></Template>;
  } catch (ex) {
    throw ex
  }
}

export function formatCurrency(value, decimals) {
  return new Intl.NumberFormat(typeof(window) == 'object' ? window.pluginConfig?.locale || 'en' : 'en', {
    style: 'currency',
    currency: typeof(window) == 'object' ? window.pluginConfig?.currencyCode || 'USD' : 'USD',
    maximumFractionDigits: decimals || 0
  }).format(parseFloat(value));
}

export function formatNumber(value, decimals) {
  return new Intl.NumberFormat(typeof(window) == 'object' ? window.pluginConfig?.locale || 'en' : 'en', {
    maximumFractionDigits: decimals || 0
  }).format(parseFloat(value));
}

export function objectPhotoAlt(item) {
  if (item.name) {
    return item.name
  } else if (item.address) {
    return item.address.street_address + ', ' + item.address.city + ', ' + item.address.state + ' ' + item.address.zip
  }
}

export function objectTitle(item) {
  if (item.name) {
    return item.name
  } else if (item.address) {
    return item.address.street_address + ', ' + item.address.city + ', ' + item.address.state + ' ' + item.address.zip
  } else if (item.title) {
    return item.title
  }
}

export function objectDescription(item) {
  if (item.object_type == 'shortcut') {
    return item.message
  } else if (item.name && item.address) {
    return item.address.street_address + ', ' + item.address.city + ', ' + item.address.state + ' ' + item.address.zip
  } else if (item.address) {
    return item.description
  } else if (item.manufacturer) {
    return item.manufacturer
  } else if (item.object_type == 'youtube') {
    return item.author + (item.duration ? " | " + formatDuration(item.duration) : '')
  }
}

export function formatDuration(duration) {
  let s = []
  let diff = duration % 3600
  if (parseInt(duration / 3600) > 0) (parseInt(duration / 3600) < 10 ? '0' : '') + s.push(parseInt(duration / 3600))
  s.push((parseInt(diff / 60) < 10 ? '0' : '') + parseInt(diff / 60))
  s.push((parseInt(diff % 60) < 10 ? '0' : '') + parseInt(diff % 60))
  return s.join(":")
}