'use client'

import { X } from "@/components/Icons"
import { forwardRef, useImperativeHandle, useState } from "react"

const Gallery = forwardRef((props,ref) => {

  const [images,setImages] = useState([])
  const [index,setIndex] = useState(-1)

  const show = (images, index) => {
    setImages(images)
    setIndex(index || 0)
  }

  const hide = (e) => {
    e.preventDefault()
    setIndex(-1)
  }

  useImperativeHandle(ref, () => ({
    show
  }))

  return <div className={"gallery " + (index >= 0 ? 'gallery-show' : '')}>
    <div className="gallery-inner">
    { index >= 0 &&
    <img src={images[index]} alt={images[index].split("/")[images[index].split("/").length -1]}></img>
    }
    </div>
    <div className="gallery-thumbs">
      <div className="gallery-thumbs-in">
      { images.map((img,idx) => (
        <a key={idx} onClick={e => onTap(e,idx)}><img src={img} alt={img.split("/")[img.split("/").length -1]}></img></a>
      ))}
      </div>
    </div>
    <a aria-label="Close" className="gallery-close" onClick={e => hide(e)}>{ X }</a>
  </div>

})

Gallery.displayName = "Gallery"
export default Gallery
