import PusherConnector from "@/plugins/PusherConnector";
import { createContext, useContext, useState } from "react";

const VisitorContext = createContext();

export function VisitorProvider(props) {

    const [visitor, setVisitor] = useState(props.visitor);

    return (
        <VisitorContext.Provider value={{ visitor, setVisitor }}>
            { visitor ? props.children : null }
            { visitor ? <PusherConnector {...props} channelId={"cc-v--" + visitor.visitor_id}></PusherConnector> : null }
        </VisitorContext.Provider>
    )
}

export function useVisitorContext() {
    return useContext(VisitorContext)
}
export function useCurrentVisitor() {
    return useContext(VisitorContext)?.visitor
}
