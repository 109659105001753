import { STAR_FILLED } from "@/components/Icons"

export default function PlanInfo(props) {

  const item = props.item

  return <>
    { item.photo ?
    <div className="chat-bubble-hero">
      <img src={ item.photo }></img>
    </div>
    : item.brochure &&
    <div className="chat-bubble-iframe">
      <iframe src={item.brochure + "#toolbar=0&navpanes=0"}></iframe>
    </div>
    }
    <div className="chat-bubble-info">
      { item.name &&
      <div><b>{ item.name }</b></div>
      }
      { item.description &&
      <div className="chat-bubble-desc">{ item.description }</div>
      }
      <div className="chat-bubble-bullets">
      { item.bullets?.map((item,i) => (
        <div key={i} className="chat-bubble-row">
          <div>{ STAR_FILLED }</div>
          <div>{ item }</div>
        </div>
      ))}
      </div>
      { (item.brochure || item.video_url) &&
      <div className="chat-bubble-buttons">
        { item.brochure &&
        <a className="button button-line" href={item.brochure} target="_brochure">Brochure</a>
        }
        { item.video_url &&
        <a className="button button-line" href={item.video_url} target="_brochure">Information Video</a>
        }
        { item.faq &&
        <a className="button button-line" href={item.faq} target="_brochure">FAQ</a>
        }
      </div>
      }
    </div>
  </>

}