import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import styles from './f.module.scss'

const Toaster = forwardRef((props, ref) => {
  
  const [hideMessage,setHideMessage] = useState()
  const [messages,setMessages] = useState([])
  const [hideMessageSalt,setHideMessageSalt] = useState(1)
  
  const toasterRef = useRef()
  
  const addMessage = (ui, ttl) => {
    let data = [...messages]
    data.push({ visible: false, ui: ui })
    setMessages(data)
    popEarliest(ttl)
  }
    
  useImperativeHandle(ref, () => ({
    addMessage, messages
  }))

  const popEarliest = (ttl) => {
    setTimeout(() => {
      if (ref.current) {
        let data = [...ref.current.messages]
        const m = data.splice(0, 1)[0]
        setHideMessageSalt(new Date().getTime())
        setHideMessage(m)
        setMessages(data)
      }
    }, ttl || 5000)
  }

  return (<div ref={toasterRef} className={ styles.toaster } aria-hidden={!messages.length == 0}> 
    { messages.map((msg,index) => (
      <div key={index} className={styles['toast']}>{ msg.text }</div>
    ))}
  </div>
  )
  
})

Toaster.displayName = 'Toaster';
export default Toaster