'use client'

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import PageHeader from "./partials/PageHeader";
import ApiUtils from "@/utils/ApiUtils";
import useEventListener from "@/plugins/EventListener";
import { VisitorProvider } from "./VisitorContext";
import RollbarPlugin from "@/plugins/RollbarPlugin";
import ChatPanel from "./partials/ChatPanel";
import { useChatEventListener } from "@ginieapp/chat-plugin";
import Popup from "@/components/popup/Popup";
import PopupBody from "@/components/popup/PopupBody";
import GlobalVideoPlayer from "./partials/GlobalVideoPlayer";
import { X } from "@/components/Icons";
import Gallery from "./partials/Gallery";
import Toaster from "@/components/popup/Toaster";

const Page = forwardRef((props, ref) => {

  const visitorNotRequired = props.public || props.embed || props.print

  const chatPanel = useRef()
  const videoPanel = useRef()
  const videoPlayer = useRef()
  const galleryPanel = useRef()
  const toaster = useRef()

  const [visitor, setVisitor] = useState(props.visitor);
  const [chat, setChat] = useState(props.cart);
  const [cart, setCart] = useState(props.cart);
  const [vehicle, setVehicle] = useState(props.vehicle);
  const [chatCollapsed, setChatCollapsed] = useState(props.chatCollapsed)
  const [video,setVideo] = useState()

  const chatSettings = props.config.chat?.settings || {}

  useEventListener("initialized", event => {
    setVisitor(event.detail)
  })

  useEventListener("play-video", event => {
    setVideo(event.detail)
    videoPanel.current.show()
  })

  useEventListener("chat_collapsed", event => {
    setChatCollapsed(event.detail)
  })

  useChatEventListener("chat-loaded", event => {
    if (event.detail && !chat) {
      setChat(event.detail)
    }
  })

  useChatEventListener("on-chat-img-tap", event => {
    const m = event.detail
    galleryPanel.current.show([m.photo || m.image_url], 0)
  })

  useEventListener("toast", event => {
    toaster.current.addMessage(event.detail)
  })

  useEffect(() => {
    if (props.cart && !cart) {
      setCart(props.cart)
    }
  }, [props.cart])

  useEffect(() => {
    if (chat && cart) {
      ApiUtils.postRequest("/v1/carts/" + cart.cart_id + "/update_cart", { chat_id: chat.id })
    }
  }, [chat,cart])

  useEffect(() => {
    ApiUtils._partnerKey = props.config.partner_key
    if (!visitorNotRequired) {
      ApiUtils.checkVisitorId(null, { ref: document.referer })
    }
  }, [])

  useEffect(() => {
    if (props.step) sendMessage(props.step)
  }, [chatPanel?.current])

  const sendMessage = (message, type) => {
    if (chatPanel?.current) {
      setTimeout(() => {
        chatPanel.current.addMessage({
          body: message,
          type: type || "system",
        })
      }, 1000)
    }
  }

  const openChat = (e) => {
    e.preventDefault()
    chatPanel.current.toggleCollapsed(e)
  }

  const stopVideo = (e) => {
    videoPlayer.current.stop()
    videoPanel.current.hide()
  }

  useImperativeHandle(ref, () => ({
    sendMessage, openChat
  }))

  const renderPage = () => {
    return (<>
      <div className={"page" + (props.print ? " page-print" : "") + (props.embed ? " page-embed" : "")}>
        { (!props.print && !props.embed) && 
        <PageHeader {...props}></PageHeader>
        }
        <main className={"web-main" + (props.fullWidth ? " web-main-iframed" : "")}>
          <div className="l-container" style={props.fullWidth ? { paddingTop:0 } : {}}>
            <div className="l-row">
              <div className="l-main">
                <div className={ chatCollapsed ? "l-rows" : "l-rows l-chat-open"}>
                  { props.children }
                </div>
              </div>
              { visitor && !props.print && !props.embed && props.chatPanel && props.config.live &&
              <div className={ "l-chat" + (chatCollapsed ? " l-chat-hidden" : "")}>
                <ChatPanel ref={chatPanel} {...props} collapsed={props.chatCollapsed} 
                  cartId={cart?.cart_id} 
                  vehicle={vehicle || cart?.vehicle} 
                  plans={cart?.items} 
                  data={ { 
                    attach_params: { 
                      "fni-plan": { vehicle_id: cart?.vehicle.vehicle_id || vehicle?.vehicle_id }, 
                      "fni-plan-info": { vehicle_id: cart?.vehicle.vehicle_id || vehicle?.vehicle_id }
                    }, 
                    app_params: Object.assign({ settings: chatSettings }, {
                      client_key: props.config.partner_key,
                      vehicle_id: cart?.vehicle.vehicle_id
                    }, vehicle || cart?.vehicle || {}) } }>
                </ChatPanel>
              </div>
              }
            </div>
          </div>
        </main>
      </div>
      <RollbarPlugin {...props}></RollbarPlugin>
      <Popup ref={videoPanel} className={'video-fs'}>
        { video &&
        <PopupBody>
          <div className="video-fs-container">
            <GlobalVideoPlayer ref={videoPlayer} video={video}></GlobalVideoPlayer>
          </div>
          <button className="video-fs-x" onClick={e => stopVideo(e)}>{ X }</button>
        </PopupBody>
        }
      </Popup>
      <Gallery ref={galleryPanel}></Gallery>
      <Toaster ref={toaster}></Toaster>
    </>)
  }

  if (!visitor && !visitorNotRequired) return <></>

  if (visitorNotRequired) {
    return renderPage()
  }

  return <VisitorProvider visitor={visitor}>
    { renderPage() }
  </VisitorProvider>

})

Page.displayName = "Page"
export default Page
