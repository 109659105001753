import React, { forwardRef, useImperativeHandle, useState } from "react"
import styles from "./f.module.scss"
import { X } from "../Icons"

const Popup = forwardRef((props, ref) => {

  const [visible,setVisible] = useState(false)

  const show = () => {
    setVisible(true)
  }
  
  const hide = (e) => {
    if (e) e.preventDefault()
    setVisible(false)
  }
  
  useImperativeHandle(ref, () => ({
    show, hide
  }))

  return <>
    <div className={styles['popup-container'] + ( visible ? ' ' + styles['popup-container-show'] : '')}>
      <div className={styles.backdrop + ' ' + ( visible ? styles['backdrop-show'] : '' )} onClick={e => props.modal ? null : hide(e)}></div>
      <div className={styles.popup + ' ' + (props.className || '') + ' ' + ( visible ? styles['popup-show'] : '' )}>
        <div className={styles['popup-inner']}>
          { props.title &&
          <div className={styles['header']}>
            <h2>{ props.title }</h2>
            <div>
              <button className={'button button-icon button-icon-only'} style={{marginRight:'8px'}} aria-label="Close" onClick={e => hide(e)}>
                { X }
              </button>
            </div>
          </div>
          }
          { props.children }
        </div>
      </div>
    </div>
  </>

})

Popup.displayName = "Popup"
export default Popup