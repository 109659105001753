import { CART } from '@/components/Icons'
import useEventListener, { emitEvent } from '@/plugins/EventListener'
import ApiUtils from '@/utils/ApiUtils'
import { useEffect, useState } from 'react'

export default function PageHeader(props) {

  const [cart,setCart] = useState()

  useEffect(() => {
    if (ApiUtils.visitorId()) {
      loadCart()
    }
  }, [])

  useEventListener("initialized", event => {
    loadCart()
  })

  useEventListener("cart_update", event => {
    if (event.detail?.cart_id) {
      setCart(event.detail)
      emitEvent("cart_loaded", event.detail)
    } else {
      loadCart()
    }
  })

  const loadCart = () => {
    // ApiUtils.getRequest("/v1/carts").then(data => {
    //   if (data.cart) {
    //     setCart(data.cart)
    //     emitEvent("cart_loaded", data.cart)
    //   }
    // })
  }

  return <nav className='header'>
    <div>
      <a className='header-logo' href="/" aria-label="Home">
        <img alt={props.config.display_name || props.config.name} src={props.config.logo} className='max-b-xs'></img>
        <img alt={props.config.display_name || props.config.name} src={props.config.logo_desktop || props.config.logo} className='min-b-xs'></img>
      </a>
    </div>
    { props.config.header?.left_menus?.map((menu,mi) => (
    <div key={mi} className='min-b-xs'>
      <a className='header-button' href={menu.url} aria-label={menu.label} target={"_blank"}>
        { menu.label}
      </a>
    </div>
    ))}
    <span></span>
    { props.config.header?.right_menus?.map((menu,mi) => (
    <div key={mi} className='min-b-xs'>
      <a className='header-button' href={menu.url} aria-label={menu.label} target={"_blank"}>
        { menu.label}
      </a>
    </div>
    ))}
    { props.showCart &&
    <div>
      <a className='button button-icon header-button' href={cart ? "/checkout/" + cart?.cart_id : ""}>
        { CART }
        { cart?.items.length > 0 &&
        <span className='button-badge'>{ cart?.items.length }</span>
        }
      </a>
    </div>
    }
    { props.getStarted &&
    <div className='min-b-xs'>
      <a className='button button-secondary button-header' href="/start" aria-label="Get Started" tabIndex={0}>
        Get Started
      </a>
    </div>
    }
  </nav>

}