import { emitEvent } from "@/plugins/EventListener"
import { formatCurrency } from "./Templates"
import { CHAT } from "@/components/Icons"
import ApiUtils from "@/utils/ApiUtils"
import { useEffect, useState } from "react"

export default function PlanDetails(props) {

  const item = props.item

  const formattedPrice = formatCurrency(item.price, 2)
  const [formattedSalePrice,setFormattedSalePrice] = useState(item.sale_price ? formatCurrency(item.sale_price) : "")
  const desc = item.description.replace(", " + formattedPrice, "")
  const [promo,setPromo] = useState()

  useEffect(() => {
    if (item.promo_code) {
      ApiUtils.getRequest("/v1/campaigns/promo_code?code=" + item.promo_code + "&price=" + item.price)
      .then(data => {
        setPromo(data.promo)
        const salePrice = item.price - data.promo.discount
        setFormattedSalePrice(formatCurrency(salePrice))
      })
    }
  }, [])

  const add = (e) => {
    e.preventDefault()
    emitEvent("add_product", { item: item, promo: promo })
  }

  return <div className="chat-bubble-info">
    <div className="chat-bubble-bullets">
      <div className="chat-bubble-row"><b>{ item.name }</b></div>
      <div className="chat-bubble-row">{ desc }</div>
      { promo &&
      <>
      <div className="chat-bubble-row" style={{marginTop:'4px'}}>
        <div className="chat-bubble-pill">{ promo.description }</div>
      </div>
      </>
      }
      { item.comment &&
      <div className="chat-bubble-comment">{ CHAT } <i>{ item.comment }</i></div>
      }
      <div className="chat-bubble-price">{ formattedSalePrice || formattedPrice }
        { formattedSalePrice &&
        <>&nbsp; <span className="chat-bubble-price-orig">{ formattedPrice }</span></>
        }
      </div>
    </div>
    <div className="chat-bubble-buttons">
      { item.addition_link &&
      <a className="button button-line" href={item.addition_link} target="_blank">{ "Additional Details" }</a>
      }
      <a className="button button-line" onClick={e => add(e)}>Select</a>
    </div>
  </div>

}
