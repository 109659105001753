'use client'

import ApiUtils from "@/utils/ApiUtils";

import { forwardRef, useRef, useEffect, useState, useImperativeHandle } from "react"
import useEventListener, { emitEvent } from "@/plugins/EventListener";
import { CHAT, X } from "@/components/Icons";
import { useCurrentVisitor } from "../VisitorContext";
import { Chat, useChatEventListener } from "@ginieapp/chat-plugin";
import PlanInfo from "./attach/PlanInfo";
import PlanDetails from "./attach/PlanDetails";
import PlanInfoRequest from "./attach/PlanInfoRequest";
import { useIsVisible } from 'react-is-visible';
import { replacePlaceholders } from "@/utils/Helpers";

export const TEMPLATES = [
  {
    name: "PlanInfo",
    template: PlanInfo,
    object_type: "fni-plan-info",  
  },
  {
    name: "PlanDetails",
    template: PlanDetails,
    object_type: "fni-plan",
  },
  {
    name: "PlanInfoRequest",
    template: PlanInfoRequest,
    object_type: "plan_info_req"
  }
]

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  return windowDimensions;
}


const ChatPanel = forwardRef((props, ref) => {

  const visitor = useCurrentVisitor()

  const windowDimensions = useWindowDimensions()

  const chatPanel = useRef()
  const chatButton = useRef()

  const chatConf = props.config.chat

  let firstMessageButtons = props.config.chat?.start_message?.buttons || []
  if (firstMessageButtons.length == 0) {
    firstMessageButtons = [
      "Vehicle Service Contracts",
      "Different Product",
      "Coverage",
      "Payment Options",
      "Other Question",
    ]
  }

  const isButtonVisible = useIsVisible(chatButton);

  const visitorId = ApiUtils.visitorId()
  const clientId = props.config.partner_key

  const [collapsed,setCollapsed] = useState(props.collapsed)
  const [chat,setChat] = useState()
  const [video,setVideo] = useState()

  let placeholderParams = {
    vehicle: props.vehicle,
    config: props.config
  }

  useChatEventListener("chat-loaded", event => {
    if (event.detail) {
      setChat(event.detail)
    }
  })

  useEventListener("request_product_info", event => {
    let plan = event.detail.plan
    plan.id = plan.product_id
    postMessage(null, { 
      body: "Can you provide more coverage details?",
      attachment: Object.assign({}, plan, { object_type: "plan_info_req" })
    })
    if (event.detail.info) {
      autoResponse({ 
        attachment: event.detail.info
      })
    }
  })

  useEffect(() => {
    if (windowDimensions) {
      if (windowDimensions.width <= 1024) {
        setCollapsed(true)
      }
    }
  }, [windowDimensions])

  useEffect(() => {
    if (chat && props.cartId) {
      ApiUtils.postRequest("/v1/carts/" + props.cartId + "/update_cart", { chat_id: chat.id })
    }
  }, [chat,props.cartId])

  const postMessage = (e, params) => {
    if (e) e.preventDefault()
    chatPanel.current.postMessage(params)
  }

  const autoResponse = (params) => {
    setTimeout(() => {
      chatPanel.current?.autoResponse(params)
    }, 5000)
  }

  const appendMessage = (message) => {
    chatPanel.current.appendMessage(message)
  }

  const toggleCollapsed = (e) => {
    e.preventDefault()
    const b = !collapsed
    setCollapsed(b)
    chatPanel?.current?.scrollToBottom()
    emitEvent("chat_collapsed", b)
  }

  const addMessage = (params, bot) => {
    chatPanel.current.postMessage(params, bot)
  }

  const isVideo = (item) => {
    if (item.content_type.indexOf("video/") == 0) {
      return true
    }
  }

  const onAttachmentOpen = (e, item) => {
    e.preventDefault()
    if (isVideo(item)) {
      if (item.url.indexOf("amazonaws") > 0) {
        emitEvent("play-video", { video_source: "link", link: item.url })
      }
    } else {
      window.open(item.url, '_blank').focus();
    }
  }
  
  const gsub = (str) => {
    return replacePlaceholders(str, placeholderParams)
  }

  useImperativeHandle(ref, () => ({
    appendMessage, addMessage, toggleCollapsed
  }))

  return <div className={"cp " + (collapsed ? 'cp-collapsed' :'')}>
    <button ref={chatButton} className={"cp-button"} aria-label="Chat with us" onClick={ e => toggleCollapsed(e) }>
      { CHAT }
    </button>
    <div className={ "cp-header" + (props.collapsed ? " cp-header-visible" : "")}>
      <div>Have Questions?</div>
      <span></span>
      <div>
        <a className="cp-header-close" onClick={e => toggleCollapsed(e)}>{ X }</a>
      </div>
    </div>
    <div className={ "cp-main"}>
      <Chat ref={chatPanel} {...props} templates={TEMPLATES} apiKey={process.env.NEXT_PUBLIC_CHAT_API_KEY} clientId={clientId} refId={visitorId}
        placeholder={ gsub(chatConf.texts?.start_placeholder || "Need help? Ask a question.") } placeholderChat={ gsub(chatConf.texts?.in_chat_placeholder || "Enter your message or question.") }
        attach={true} sms={false} call={false} bodyStyle={"cp-view"} footerStyle={"cp-footer"}
        bubbleRowStyle="chat-messages-row" bubbleMyRowStyle="chat-messages-my" bubbleStyle="chat-bubble"
        attachButtonStyle="button" attachButtonContainer="chat-bubble-buttons chat-bubble-info"
        title={visitor.city + ", " + visitor.state} description={visitor.client?.name} collapsed={collapsed}
        app_params={ Object.assign({}, props.app_params, { visitor_id: ApiUtils.visitorId() }) }
        onAttachmentOpen={(e,link) => onAttachmentOpen(e,link)}>
        <div>
          <div>{ gsub( chatConf?.start_message?.message || "👋 Welcome to [client]! How can we help you today?") }</div>
          <div className="chat-bubble-buttons">
            { firstMessageButtons.map((b,i) => (
              <button key={i} className="button" aria-label={gsub(b)} onClick={e => postMessage(e, {body: gsub(b)})}>{gsub(b)}</button>
            ))}
          </div>
        </div>
      </Chat>
    </div>
  </div>

})

ChatPanel.displayName = "ChatPanel"
export default ChatPanel

// npm link /Users/saurav/dev/ginie/chat/chat-ui  /Users/saurav/dev/ginie/chat/chat-plugin /Users/saurav/dev/ginie/ginie-ui