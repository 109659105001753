'use client'

import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"

const GlobalVideoPlayer = forwardRef((props,ref) => {

  const video = props.video

  const videoPlayer = useRef()

  const [ytPlayerReady,setYtPlayerReady] = useState(false)
  const [ytPlayer,setYtPlayer] = useState()
  const [state,setState] = useState("stopped")

  window.onYouTubeIframeAPIReady = () => {
    ref.current.setYtPlayerReady(true)
    if (props.video) {
      playYtVideo(url())
    }
  }

  useEffect(() => {
    if (video && !isYTVideo()) {
      play(url())
      setState("start")
    }
  }, [])

  const url = () => {
    if (video.video_source == "youtube") return video.yt_link
    if (video.video_source == "upload") return video.upload
    if (video.video_source == "link" || video.video_source == "iframe") return video.link
  }

  const isYTVideo = () => {
    return video.video_source == "youtube"
  }

  const isIframeVideo = () => {
    return video.video_source == "iframe"
  }

  const getYTVideoId = (url) => {
    if (url.indexOf("http") == 0) {
      const urlParts = url.includes("/embed/") ? url.split("/embed/") : url.includes("/watch/") ? url.split("/watch/") : url.split("/watch?v=")
      const videoId = urlParts[urlParts.length-1]
      return videoId
    } else {
      return url
    } 
  }

  const playYtVideo = (url) => {
    if (isYTVideo(url)) {
      const videoId = getYTVideoId(url)
      setYtPlayer(new YT.Player('global_yt_video_player', {
        height: '100%',
        width: '100%',
        videoId: videoId,
        playerVars: {
          'showinfo': 0,
          'playsinline': 1,
          'autoplay': true,
          // 'controls': 0,
          'enablejsapi': 1,
          'mute': false,
          loop: 1
        },
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        }
      }));
    }
  }

  const play = (url) => {
    if (isYTVideo(url) && ytPlayerReady && ytPlayer) playYtVideo(url)
  }

  const stop = () => {
    if (isYTVideo()) {
      ytPlayer.stopVideo()
      ytPlayer.destroy()
    } else {
      if (videoPlayer.current) {
        videoPlayer.current.pause();
        videoPlayer.current.currentTime = 0;
      }
      setState("stopped")
    }
  }

  const onPlayerReady = (event) => {
    if (isYTVideo()) {
      event.target.playVideo();
    }
  }

  const onPlayerStateChange = (event) => {
  }

  useImperativeHandle(ref, () => ({
    play, stop, setYtPlayerReady
  }))

  if (isYTVideo()) {
    return <>
      <script src="https://www.youtube.com/iframe_api" async></script>
      <div id="global_yt_video_player"></div>
    </>
  } else if (isIframeVideo()) {
    if (state == "start") {
      return <iframe src={ url() } width="100%" height="100%" allow="autoplay; fullscreen" allowFullScreen></iframe>
    }
  } else if (url()) {
    return <video ref={videoPlayer} controls width="100%" height="100%" autoPlay={true}>
      <source src={ url() } type="video/mp4" />
    </video>
  }

})

GlobalVideoPlayer.displayName = "GlobalVideoPlayer"
export default GlobalVideoPlayer
