'use client'

import { forwardRef, useEffect } from 'react'
import Pusher from 'pusher-js'
import { emitEvent } from './EventListener'

const PusherConnector = forwardRef((props, ref) => {

  useEffect(() => {

    const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY, {
      cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
      encrypted: true
    });
    pusher.connection.bind( 'error', function( err ) {
      if (err.data?.code != 1006 && err.data?.code != 4201) {
        log("---- PUSHER ERROR")
        log(err)
      }
    });
    const channelId = props.channelId
    const channel = pusher.subscribe(channelId);
    log("PUSHER >>> " + channelId + " >>> BIND message")
    channel.bind_global((event, data) => {
      if (event.indexOf('pusher:') != 0) {
        log("PUSHER MESSAGE: ", data)
        emitEvent(event, data)
      }
    });
    pusher.connection.bind( 'error', function( err ) {
      if( err.data?.code === 4004 ) {
        log('Over limit!');
        emitEvent("log_error", { message: "Pusher Over Limit!", data: { app_key: process.env.NEXT_PUBLIC_PUSHER_KEY }})
      }
    });

    return () => {
      log("PUSHER ---> DISCONNECT")
      if (pusher) pusher.disconnect();
    }
  }, [])

  const log = (message, data) => {
    if (process.env.NODE_ENV == 'development') console.log(message)
    if (process.env.NODE_ENV == 'development' && data) console.log(data)
  }
  
})

PusherConnector.displayName = "PusherConnector"
export default PusherConnector