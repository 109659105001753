'use client'

import { useEffect, useState } from "react"
import Rollbar from "rollbar"
import { useCurrentVisitor } from "@/views/VisitorContext"
import { useCurrentUser } from "@/views/agent/UserContext"
import useEventListener from "./EventListener"

/**
 * Adds Rollbar plugin to your site.
 *  
 * To log en error, use the following: 
 *   emitEvent("log_error", { message: "Error message.", detail: { ... more detail like params, exception etc. }})
 * 
 * To set the user information, use the following: 
 *   emitEvent("log_user_id", { id: user.id, email: user.email })
 * 
 * @param enabled Whether rollbar is enabled or not.
 * @param accessToken Client side access token.
 * @param env The deployment environment, like production or staging.
 * @param codeVersion The version of your code.
 * @param branch The branch of your code. Default is main.
 * @param domain The domain of your site. eg. https://www.example.com
 * @param userId The user ID , if any.
 * @returns 
 */
export default function RollbarPlugin(props) {

  const visitor = useCurrentVisitor()
  const currentUser = useCurrentUser()

  const [rollbar,setRollbar] = useState()

  useEffect(() => {
    if (rollbar && visitor) {
      rollbar.configure({ payload: { person: {
        id: visitor.visitor_id,
      } } });
    }
    if (rollbar && currentUser) {
      rollbar.configure({ payload: { person: {
        id: currentUser.user_id,
        username: currentUser.first_name + " " + currentUser.last_name,
        email: currentUser.email
      } } });
    }
  }, [rollbar, visitor, currentUser])

  useEventListener("log_error", event => {
    rollbar.error(event.detail.message, event.detail.data)
  })
  
  useEffect(() => {
    const rollbarConfig = {
      enabled: process.env.NODE_ENV != "development",
      accessToken: "7cd62d8fbecc4f118b6f49588f3eb140",
      payload: {
        environment: process.env.NODE_ENV == "development" ? "development" : process.env.NEXT_PUBLIC_ENV,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: "1.0.0",
            guess_uncaught_frames: true
          }
        },
        server: {
          root: window.location.origin,
          branch: 'main',
        },
        code_version:  "1.0.0",
        custom: {
          site: window.location.origin,
          client_id: props.config?.partner_key,
          client: props.config?.name
        },
        person: {
          id: currentUser?.user_id
        }
      },
      captureUncaught: true,
      captureUnhandledRejections: true,
    }
  
    setRollbar(new Rollbar(rollbarConfig))
    
  }, [])
  
  return <></>
  
}